import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Grid, Link, Typography,
} from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { useTranslation } from 'react-i18next';
import FileDropzone from '../../../Common/Components/FileDropzone';
import AssignmentTextEditor from './AssignmentTextEditor';

const ProjectAssignmentStyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  height: '100%',
}));

const SubmitAssignment = ({
  step,
  droppedFilesCallback,
  textEditorChangeCallback,
  currentStatus,
}) => {
  const { t } = useTranslation('ProjectSubmission');
  const submissionText = step.assessment_submission?.submission_text || '';

  const getAcceptedFiles = () => {
    if (step.submission_type === 'image') return { image: true };

    return { text: true };
  };

  const acceptedFiles = useMemo(() => getAcceptedFiles(), [step.submission_type]);

  const AssessmentHeading = ({ children }) => (
    <Typography variant="h3" sx={{ mb: 2 }}>
      {children}
    </Typography>
  );

  const renderBeginningMessage = () => (
    <Box>
      <AssessmentHeading>{t('submitAssignment.heading')}<AutoAwesomeIcon /></AssessmentHeading>
      {step.submission_type === 'text' && (
        <Typography sx={{ mb: 2 }}>{t('submitAssignment.body1Text')}</Typography>
      )}
      {step.submission_type !== 'text' && (
        <Typography sx={{ mb: 2 }}>{t('submitAssignment.body1Images')}</Typography>
      )}
      <Typography>
        <Link href="https://support.kubicle.com/assignments-within-projects-on-kubicle" target="_blank">
          {t('submitAssignment.link')}
        </Link>
      </Typography>
      <Typography sx={{ mt: 2 }}><i>{t('submitAssignment.body2')}</i></Typography>
    </Box>
  );

  return (
    <ProjectAssignmentStyledGrid container alignItems="center">
      <ProjectAssignmentStyledGrid item xs={12} md={6}>
        {renderBeginningMessage()}
      </ProjectAssignmentStyledGrid>
      <ProjectAssignmentStyledGrid item xs={12} md={6}>
        {step.submission_type === 'text' && (
          <AssignmentTextEditor
            onContentChange={textEditorChangeCallback}
            initialContent={submissionText}
            disabled={(currentStatus === 'processing')}
          />
        )}
        {step.submission_type !== 'text' && (
          <FileDropzone
            returnFiles={droppedFilesCallback}
            accepted_files={acceptedFiles}
          />
        )}
      </ProjectAssignmentStyledGrid>
    </ProjectAssignmentStyledGrid>
  );
};

export default SubmitAssignment;
